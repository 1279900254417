.site-brand__svg {
    width: 9em;
    height: 2em;
}

.cemaco-button {
    background-color: #101e8e !important;
    color: #eeeeee !important;
    font-weight: bold;
}

.cemaco-button-green {
    background-color: #94D500 !important;
    color: #1d1d1d !important;
    font-weight: bold;
}